@media screen and (max-width: 1024px) {
  .catalog__card {
    width: 100% !important;
    margin-right: 0 !important;
  }
  app-card:nth-child(even) .catalog__category {
    flex-direction: row-reverse;
  }
}

@media screen and (max-width: 768px) {
  .preset_style {
    width: calc((100% - 30px) / 2) !important;
  }
}

@media screen and (max-width: 600px) {
  .catalog__card {
    min-width: auto !important;
  }

  .catalog__category {
    flex-direction: column !important;
  }

  .catalog__tree {
    display: none;
  }
  .catalog__body {
    flex: 0 0 100% !important;
  }

  .preset_style {
    flex-direction: column;
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: 500px) {
  .preset_style {
    width: 100% !important;
  }
}


