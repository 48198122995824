@media screen and (max-width: 1024px) {
  .qr-fields-doubled {
    flex-direction: column;
    & mat-form-field {
      width: 100% !important;
    }
  }
}

@media screen and (min-width: 801px) {
  .qr-preview-container {
    flex-basis: 35%;
  }
}

@media screen and (max-width: 800px) {
  .qr {
    padding: 0 !important;
  }
  app-qr-options, app-qr h1, .qr-code__seo-description {
    padding: 0 16px;
  }

  app-qr bar-blocks-angular-renderer {
    padding: 30px 16px !important;
  }

  .qr__content {
    flex-direction: column-reverse !important;
    gap: 0 !important;
  }
  .qr__fabs {
    display: flex !important;
  }
  .qr__other-qr {
    display: none !important;
  }
  .qr__download-text {
    display: none !important;
  }
  .qr__download-mobile {
    display: flex !important;
  }

  .qr-preview {
    position: static !important;
  }

  .qr__preview {
    height: 300px !important;
  }

  .qr__add-image {
    flex-direction: column;
    align-items: flex-start !important;
    gap: 10px;
    & span  {
      max-width: 100% !important;
    }
  }

  .qr-hide-on-mobile {
    display: none !important;
  }

  .qr-show-on-mobile {
    display: block !important;
  }

  .qr-add-logo-button {
    display: block !important;
  }

  .qr-add-logo-dropzone {
    display: none !important;
  }
}

@media screen and (max-width: 400px) {
  .event-time {
    flex-direction: column;
  }
  .qr__colors {
    flex-direction: column;
    align-items: flex-start !important;
    gap: 16px
  }
}

