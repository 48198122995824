@media screen and (max-width: 768px) {
  .article__preview {
    max-height: 400px !important;
  }

  .news-card__image {
    padding: 0 !important;
  }

  .news-page {
    padding-left: 0 !important;
    padding-right: 0 !important;

    &__content {
      padding: 0 30px;
    }
  }

  .padding-mobile {
    padding: 0 30px;
  }

  .show-pagi-pc {
    display: none !important;
  }
}

@media screen and (max-width: 500px) {
  .news {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr)) !important;
  }
  .news-card__content {
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }

  .article__preview {
    max-height: 250px !important;
    object-fit: cover !important;
  }

  .news-card__description {
    max-width: 100% !important;
  }

  .news-page__title {
    font-size: 40px !important;
  }
}

@media screen and (max-width: 350px) {
  .news {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr)) !important;
  }
  .news-card__content {
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }

  .article__preview {
    max-height: 180px !important;
  }
}
