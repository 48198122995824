@media screen and (max-width: 1024px) {
  .pricing {
    &__plans {
      flex-wrap: wrap;
      gap: 30px
    }

    &__plan {
      flex: 0 0 45% !important;
    }
  }
}

@media screen and (max-width: 700px) {
  .pricing {
    &__plan {
      flex: 0 0 100% !important;
      box-shadow: none !important;
    }

    &__plans {
      display: none !important;

      &-mobile {
        display: block !important;
      }
    }
  }

  .pricing__plans-mobile .mdc-tab {
    padding: 0 !important;
    min-width: auto !important;
  }
}
