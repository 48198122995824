@media screen and (max-width: 900px) {
  .sign-in {
    flex-direction: column;

    &__side {
      padding: 50px 0 !important;
    }

    &__form {
      padding: 0 !important ;
    }
  }

  .auth-qr-code {
    width: 150px;
    height: 150px;
    border-radius: 10px !important;
  }
}
