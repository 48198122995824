@media screen and (max-width: 1050px) {
  .burger {
    display: block !important;
  }
  .header__hide-laptop {
    display: none !important;
  }

  app-solutions-dropdown {
    left: -30px !important
  }
}

@media screen and (max-width: 768px) {
  .header__hide-tablet {
    display: none;
  }

  app-locations {
    width: 80% !important;
    margin: 0 auto;
  }
}

@media screen and (max-width: 500px) {
  .locations-search__body {
    display: block !important;
  }

  .header__hide-mobile {
    display: none;
  }

  .show-on-mobile {
    display: flex !important;
  }

  .header-menu {
    flex-direction: row-reverse;
  }

  .header-menu__content {
    gap: 0 !important;
  }

  app-locations {
    max-height: 100vh !important;
    height: 100vh;
    width: 100vw !important;
    border-radius: 0 !important;
    padding: 0 16px;
  }

  .locations__langs {
    flex-wrap: nowrap !important;
    flex-direction: column;
    align-items: flex-start !important;
  }

  .auth-link {
    display: none !important;
  }

  .menu-auth-buttons {
    flex-direction: column;

    & button {
      width: 100% !important;
    }
  }
}


