@use '@angular/material' as mat;
@use '@angular/material-experimental' as matx;

@use "assets/styles/normalize" as *;
@use "assets/styles/variables"  as *;
@use "assets/styles/media-query/media.index"  as *;

@include mat.core();

$button-typography: mat.m2-define-typography-config(
  $button: mat.m2-define-typography-level(
    $font-family: 'Roboto Flex, sans-serif',
    $font-weight: 700,
    $font-size: 14px,
    $line-height: 18px,
    $letter-spacing: 0.06em,
  )
);

$light-palette-primary: (
  50: #e3eff2,
  100: #b9d9dd,
  200: #62aab0,
  300: #62aab0,
  400: #4299a0,
  500: $main,
  600: #297c80,
  700: #256d6f,
  800: #215d5f,
  900: #194241,
  contrast: (
    500: white,
  )
);

$light-palette-accent: (
  50: #e3eff2,
  100: #b9d9dd,
  200: #62aab0,
  300: #62aab0,
  400: #4299a0,
  500: #D2E1E2,
  600: #297c80,
  700: #256d6f,
  800: #215d5f,
  900: #194241,
  contrast: (
    500: $main,
  )
);


$primary: mat.m2-define-palette($light-palette-primary);
$accent: mat.m2-define-palette($light-palette-accent);

//$theme: mat.define-light-theme((
//  color: (
//    primary: $primary,
//    accent: $accent,
//  )
//));

//$theme: matx.define-theme((
//  color: (
//    theme-type: light,
//    primary: (
//      0: red,
//      10: red,
//      20: red,
//      25: red,
//      30: red,
//      35: red,
//      40: white,
//      50: #944aff,
//      60: #a974ff,
//      70: #bf98ff,
//      80: #d5baff,
//      90: red,
//      95: red,
//      98: red,
//      99: red,
//      100: red,
//      secondary: (
//        0: #000000,
//        10: #1f182a,
//        20: #352d40,
//        25: #40384c,
//        30: #4b4357,
//        35: #574f63,
//        40: #645b70,
//        50: #7d7389,
//        60: #978ca4,
//        70: #b2a7bf,
//        80: #cec2db,
//        90: #eadef7,
//        95: #f7edff,
//        98: #fef7ff,
//        99: red,
//        100: red,
//      ),
//      neutral: (
//        0: #000000,
//        10: #1d1b1e,
//        20: #323033,
//        25: #3d3a3e,
//        30: #49464a,
//        35: #545155,
//        40: #605d61,
//        50: #7a767a,
//        60: #948f94,
//        70: #aeaaae,
//        80: #cac5ca,
//        90: #e6e1e6,
//        95: #f5eff4,
//        98: #fef8fc,
//        99: $main,
//        100: red,
//      ),
//      neutral-variant: (
//        0: #000000,
//        10: #1d1a22,
//        20: #332f37,
//        25: #3e3a42,
//        30: #49454e,
//        35: #55515a,
//        40: #615c66,
//        50: #7b757f,
//        60: #958e99,
//        70: #b0a9b3,
//        80: #cbc4cf,
//        90: #e8e0eb,
//        95: #f6eef9,
//        98: #fef7ff,
//        99: red,
//        100: red,
//      ),
//    ),
//  ),
//));

$m3-green-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: mat.$green-palette,
  ),
));

.light-theme {
  @include mat.all-component-themes($m3-green-theme);
  @include mat.button-typography($button-typography);

  //--mat-bottom-sheet-container-background-color: white;
  //--mdc-chip-elevated-selected-container-color: #378088;
  //--mdc-chip-selected-label-text-color: white;
  //--mdc-chip-with-icon-selected-icon-color: white;
  //--mat-table-background-color: white;
  //--mdc-dialog-container-color: white;
}

cdk-dialog-container {
  outline: none;
}


* {
  box-sizing: border-box;
  font-family: 'Roboto Flex', sans-serif;
}

 button {
  -webkit-user-select: none !important;
 }

 .webkit-select {
   -webkit-user-select: auto;
 }

.mat-raised-button {
  text-transform: uppercase;
  letter-spacing: 0.06em;
}

button { height: 40px !important;}

html {
  background-color: $background;
}

html,
body {
  padding: 0;
  margin: 0;
}

.wrapper {
  padding: 0 30px 30px 30px;
}

.container {
  padding: 30px;
}

.header {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 26px;
  color: $header;
  text-overflow: ellipsis;
  width: auto;
  overflow: hidden;
  text-align: center
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: $header
}

.subtext {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: $text;
}

// MatDialog background
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  background-color: #2C373B;
  opacity: .5;
}

.mat-dialog-container {
  padding: 20px;
  border-radius: 20px 20px 0 0 !important;
}

.error {
  color: $error;
  margin-top: 10px;
  display: block;
}

.info {
  color: #026e00;
}

.loader {
  align-self: center;
}

.mat-bottom-sheet-container {
  padding: 20px !important;
}

.full-loader {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100000;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0,0,0,.4);
}

input, input:before, input:after {
  -webkit-user-select: initial;
  -khtml-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;
  user-select: initial;
}

.pointer {
  cursor: pointer ;
}

.scale-1-1 {
  transition: .4s;

  &:hover {
    transform: scale(1.1);
  }
}

.tests {
  width: 100vw;
  height: 100vh;
  & > img {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1101;
    object-fit: contain;
    background-color: white;
  }
  &__buttons {
    position: absolute;
    bottom: 10%;
    left: 50%;
    z-index: 1102;
    transform: translateX(-50%);
    & > button:first-child {
      margin-right: 20px;
    }
  }
}

.table-input {
  border: none;
  outline: none;
  text-overflow: ellipsis !important;
  white-space: nowrap;
  background: transparent;
  &-file {
    display: none;
  }
  &-label {
    display: flex;
    align-items: center;
  }

  &:focus-visible {
    border-bottom: 1px solid $main;
  }
}

.g-nav-next, .g-nav-prev {
  height: 80vh !important;
  padding-left: 15px;
  width: 50px !important;
}

.g-nav-next {
  right: 0 !important;
}

.g-nav-prev {
  left: 0 !important;
}

//.g-image-item {
//  object-fit: fill !important;
//}

@media only screen and (min-width: 480px) {
  lightbox {
    max-height: 100vh !important;
    max-width: 100vw !important;
    width: 100vw !important;
    height: 100vh !important;
  }
}

@media only screen and (max-width: 389px) {
  .scan-list-settings__column-input {
    width: 60px !important;
  }
  .scan-table-settings__columns {
    padding: 0 !important;
  }
  .localization-icon {
    display: none !important;
  }
  .menu__link-title {
    display: none;
  }
}


::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

ngx-mat-calendar {
  background: white;
}

.flex {
  display: flex;
}

.align-c {
  align-items: center;
}

.justify-sb {
  justify-content: space-between;
}

.fdc {
  flex-direction: column;
}

.article {
  max-width: 800px;
  margin: 0 auto;
}

.card {
  width: 100%;
  min-height: 45px;
  background-color: white;
  border-radius: 20px;
  padding: 12px 20px;
  display: flex;
  align-items: center;
}

input[type="color"] {
  -webkit-appearance: none;
  border: none;
  width: 28px;
  height: 26px;
}
input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}
input[type="color"]::-webkit-color-swatch {
  border: none;
}

.article {
  max-width: 800px;
  margin: 0 auto;
  & h1 {
    font-size: 60px;
  }
  & h2 {
    font-size: 40px;
  }
}

a {
  text-decoration: none;
  color: inherit;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

.mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
  padding-left: 0 !important;
}

.resizer {
  width: 10px;
  height: 10px;

  position: absolute;
  z-index: 10;

  &.top-left {
    top: -5px;
    left: -5px;
    cursor: nwse-resize;
  }
  &.top-right {
    top: -5px;
    right: -5px;
    cursor: nesw-resize;
  }
  &.bottom-left {
    bottom: -5px;
    left: -5px;
    cursor: nesw-resize;
  }
  &.bottom-right {
    bottom: -5px;
    right: -5px;
    cursor: nwse-resize;
  }

  &.top {
    z-index: 9 !important;
    top: -5px;
    left: calc(50% - 5px);
    cursor: ns-resize;
  }

  &.bottom {
    z-index: 9 !important;
    bottom: -5px;
    left: calc(50% - 5px);
    cursor: ns-resize;
  }

  &.right {
    z-index: 9 !important;
    right: -5px;
    top: calc(50% - 5px);
    cursor: ew-resize;
  }

  &.left {
    z-index: 9 !important;
    left: -5px;
    top: calc(50% - 5px);
    cursor: ew-resize;
  }

  &.rotate {
    top: -20px;
    right: -20px;
    cursor: url("/assets/icons/rotate.svg"), pointer;
    background: #00b531;
  }
}

.qr-builder-selected-element > .resizer {
  border-radius: 50%;
  background: #00b531;
}

.accent-button {
  color: white !important;
  background: #0a9407 !important;

  transition: .4s;

  &:hover {
    background: #188a16 !important;
  }
}
