@media screen and (max-width: 1024px) {
  .footer__blog {
    display: none !important;
  }

  .footer {
    padding: 50px !important;
  }
}

@media screen and (max-width: 768px) {
  .footer__templates {
    display: none !important;
  }

  .footer__body {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .google-maps-review {
    align-items: center;
  }
}
