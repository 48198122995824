@media screen and (max-width: 1440px) {
  app-qr-codes-table, app-qr-code-analytics-table {
    max-width: calc(80vw - 60px);
    overflow-x: auto;
  }

  .qr-designs {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }
}


@media screen and (max-width: 1024px) {
  .dynamic-left-widget {
    display: none !important;
  }

  app-qr-codes-table, app-qr-code-analytics-table {
    max-width: calc(100vw - 104px - 60px);
    overflow-x: auto;
  }

  app-cabinet-sidebar {
    flex: none !important;
  }

  .cabinet-sidebar__top > app-full-logo {
    display: none !important;
  }

  .cabinet-sidebar__link > span {
    display: none;
  }

  .cabinet-qr-code__preview {
    display: none;

    &-self {
      display: block !important;
      margin: 0 auto;
      margin-bottom: 20px;
    }
  }

  .qr-designs {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }

  .qr-design__img-block {
    width: 70% !important;
  }
}

@media screen and (max-width: 768px) {
  app-qr-codes-table, app-qr-code-analytics-table {
    max-width: calc(100vw - 68px);
    overflow-x: auto;
  }

  .cabinet-qr-code__header {
    flex-direction: column;
    gap: 20px
  }

  .cabinet-wrapper {
    padding: 30px 30px 110px 30px !important;
  }

  app-cabinet-sidebar {
    display: none;
  }

  .cabinet-footer {
    display: flex !important;
  }

  .cabinet-qr-page-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 20px;
  }

  .cabinet-qr-page-body {
    gap: 20px;
    flex-direction: column-reverse !important;
  }

  .cabinet-qr-page-actions {
    justify-content: center;
  }

  app-save-qr-modal > div {
    width: 320px !important;
  }

  .cabinet-qr-code input {
    width: 250px;
  }

  .qr-designs {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }

  .qr-design__img-block {
    width: 100% !important;
  }

  .qr-design__header {
    flex-direction: column !important;
    gap: 20px !important;
  }

  .qr-design-hide-mobile {
    display: none !important;
  }

  .qr-design-show-mobile {
    display: flex !important;
  }
}

@media screen and (max-width: 600px) {
  .cabinet-qr-code__info {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .cabinet-qr-code__content {
    max-height: none !important;
  }
}

@media screen and (max-width: 500px) {
  .qr-designs {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }

  .cabinet__more-links {
    display: block !important;
  }

  .cabinet__logout {
    display: none !important;
  }
}
