@media screen and (max-width: 800px) {
  .welcome {
    &__content {
      flex-direction: column !important;
    }
    &__button {
      width: 100% !important;
    }
    &__title, &__subtitle {
      text-align: center !important;
      max-width: 100% !important;
    }
  }
}

@media screen and (max-width: 500px) {
  .welcome {
    &__img {
      width: 280px;
      height: 280px;
      margin-top: 20px;
    }
  }

  .article__welcome {
    flex-direction: column-reverse !important;
    align-items: center;
    & > div {
      align-items: center !important;
    }
  }


}

@media screen and (max-width: 300px) {
  .welcome {
    &__title {
      font-size: 38px !important;
    }
  }
}
