@media screen and (max-width: 500px) {
  .contact-form {
    width: 100% !important;
  }

  .pos-terminal {
    & h1 {
      font-size: 40px !important;
    }

    video {
      max-height: 300px !important;
    }
  }

  .pos-terminal__pricing {
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
  .pos-terminal__header {
    flex-direction: column;
    margin-bottom: 0;

    &-info {
      align-items: center;

      & h1 {
        text-align: center;
      }

      & p {
        text-align: center;
      }
    }
  }
}
